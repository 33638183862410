import React, { useState, createContext } from 'react';

const FeatureThumbnailContext = createContext();

export function FeatureThumbnailProvider({ children }) {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedThumbnail, setSelectedThumbnail] = useState('');

	const handleSelectThumbnail = (e, thumbnail) => {
		e.preventDefault();
		setSelectedThumbnail(thumbnail);
		setModalOpen(true);
	};

	return (
		<FeatureThumbnailContext.Provider
			value={{
				modalOpen,
				setModalOpen,
				selectedThumbnail,
				setSelectedThumbnail,
				handleSelectThumbnail,
			}}>
			{children}
		</FeatureThumbnailContext.Provider>
	);
}

export default FeatureThumbnailContext;
