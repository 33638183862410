export const initialFormValues = {
	techCount: 5,
	hoursSpent: 4,
	dailyDistance: 30,
	avgWage: 13.4,
	avgDriveTime: 6,
	avgFuelPrice: 2.85,
	timePeriod: 1,
	email: '',
	name: '',
};

export const formValuesReducer = (state, action) => {
	return {
		...state,
		...action,
	};
};
