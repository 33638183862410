import React, { useState, createContext } from 'react';
import { blockContentToPlainText } from 'react-portable-text';

const FaqSearchContext = createContext();

export function FaqSearchProvider({ children }) {
	const [searchTerm, setSearchTerm] = useState('');
	const [defaultFaq, setDefaultFaq] = useState([]);
	const [filteredFaq, setFilteredFaq] = useState([]);

	//Adds a value to each object that keeps the title, description, and any tags set in sanity as one big string that the searchTerm is compared
	//and matched to for filtering. Further sorts by the primary 7 industries from home mosaic sorted by size, then secondary 7 sorted by size, then the rest alphabetically
	const setDefault = (faqs) => {
		setDefaultFaq(faqs);
		setFilteredFaq(faqs);
	};

	//onkeyup sets a new state based on matched search filtering for other industries when
	//there is a search query, but nothing matches
	const handleChange = (e, defaultFaq, faqs) => {
		const { value } = e.target;
		setSearchTerm(value);
		if (value) {
			let newFaqQuestion = defaultFaq.filter((faq) =>
				faq.question.toLowerCase().includes(value.toLowerCase())
			);
			let newFaqAnswer = defaultFaq.filter((faq) =>
				blockContentToPlainText(faq._rawAnswer)
					.concat()
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase())
			);
			setFilteredFaq([...newFaqQuestion, ...newFaqAnswer]);
		} else {
			setDefault(faqs);
		}
	};

	return (
		<FaqSearchContext.Provider
			value={{
				defaultFaq,
				setSearchTerm,
				setDefaultFaq,
				filteredFaq,
				setFilteredFaq,
				setDefault,
				handleChange,
			}}>
			{children}
		</FaqSearchContext.Provider>
	);
}

export default FaqSearchContext;
