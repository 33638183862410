import React, { useState, createContext } from 'react';

const LitePageContext = createContext();

export function LitePageProvider({ children }) {
	const [isLite, setIsLite] = useState(false);
	return (
		<LitePageContext.Provider
			value={{
				isLite,
				setIsLite,
			}}>
			{children}
		</LitePageContext.Provider>
	);
}

export default LitePageContext;
