import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
	AppBar,
	Toolbar,
	Typography,
	Container,
	Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	footer: {
		background: '#f4f8ff',
		color: '#002D5C',
		overflowX: 'hidden',
		marginTop: '-8px',
		boxShadow: 'none',
	},
	headerText: {
		fontWeight: 'bold',
		marginBottom: '1rem',
		textAlign: 'left',
	},
	linkText: {
		textDecoration: 'none',
		marginBottom: '10px',
		color: '#002D5C',
		fontSize: 16,
		textAlign: 'left',
		opacity: '1',
		transition: 'opacity .25s ease-in-out',
		'&:hover': {
			opacity: '0.6',
		},

		[theme.breakpoints.down('lg')]: {
			minHeight: 'auto',
			minWidth: 'auto',
		},
	},
	linkTextSocial: {
		opacity: '1',
		maxWidth: '25px',
		transition: 'opacity .25s ease-in-out',
		'&:hover': {
			opacity: '0.6',
		},
	},
	linkTextAddress: {
		textDecoration: 'none',
		marginBottom: '10px',
		color: '#002D5C',
		fontSize: 16,
		textAlign: 'left',
		// opacity: '1',
		// transition: 'opacity .25s ease-in-out',
		// '&:hover': {
		// 	opacity: '0.6',
		// },

		[theme.breakpoints.down('lg')]: {
			minHeight: 'auto',
			minWidth: 'auto',
		},
	},

	footerLogo: {
		[theme.breakpoints.down('lg')]: {
			textAlign: 'center',
		},
	},

	footerSocialContainer: {
		[theme.breakpoints.down('lg')]: {
			textAlign: 'center',
		},
	},
}));

export const Footer = () => {
	const classes = useStyles();
	const { footer } = useStaticQuery(graphql`
		query {
			footer: sanityFooterContent {
				featureLinks {
					title
					slug {
						current
					}
					internalLink
				}
        whyPestPacLinks {
          title
          slug {
            current
          }
          internalLink
        }
				resourceLinks {
					title
					slug {
						current
					}
					internalLink
				}
				productLinks {
					title
					slug {
						current
					}
					internalLink
				}
				reachUsContent {
					salesNumber
					supportNumber
					address
				}
				wwFooterLogo {
					asset {
						url
						gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 220)
					}
				}
			}
		}
	`);
	const med = useMediaQuery('(max-width: 960px)');
	return (
		<div>
			<AppBar position='static' className={classes.footer}>
				<Container fixed>
					<Toolbar style={{ padding: '0 0 3rem 0' }}>
						<Grid container direction='row'>
							<Grid
								container
								direction='row'
								justifyContent='space-evenly'
								alignItems='flex-start'
								spacing={6}
								style={{ textAlign: 'center', paddingTop: '0' }}>
								<Grid item xs={6} md={3}>
									<Grid
										container
										direction='column'
										justifyContent='flex-start'
										alignItems='flex-start'>
										<Typography className={classes.headerText}>
											Why PestPac?
										</Typography>
										{footer.whyPestPacLinks.map((whyPestPac, index) => 
                      whyPestPac.internalLink ? (
											<Link
												key={index}
												to={`/${whyPestPac.slug.current}`}
												className={classes.linkText}>
												{whyPestPac.title}
											</Link>
                      ) : (
												<a
													key={index}
													target='_blank' aria-label="Opens in a new tab"
													rel='noopener'
													href={whyPestPac.slug.current}
													className={classes.linkText}>
													{whyPestPac.title}
												</a>
											)
										)}
									</Grid>
								</Grid>
								<Grid item xs={6} md={3}>
									<Grid
										container
										direction='column'
										justifyContent='flex-start'
										alignItems='flex-start'>
										<Typography className={classes.headerText}>
											Features
										</Typography>
										{footer.featureLinks.map((feature, index) =>
											feature.internalLink ? (
												<Link
													key={index}
													to={`/${feature.slug.current}`}
													className={classes.linkText}>
													{feature.title}
												</Link>
											) : (
												<a
													key={index}
													target='_blank' aria-label="Opens in a new tab"
													rel='noopener'
													href={feature.slug.current}
													className={classes.linkText}>
													{feature.title}
												</a>
											)
										)}
									</Grid>
								</Grid>
								<Grid item xs={6} md={3}>
									<Grid
										container
										direction='column'
										justifyContent='flex-start'
										alignItems='flex-start'>
										<Typography className={classes.headerText}>
											Resources
										</Typography>
										{footer.resourceLinks.map((resource, index) =>
											resource.internalLink ? (
												<Link
													key={index}
													to={`/${resource.slug.current}`}
													className={classes.linkText}>
													{resource.title}
												</Link>
											) : (
												<a
													key={index}
													target='_blank' aria-label="Opens in a new tab"
													rel='noopener'
													href={resource.slug.current}
													className={classes.linkText}>
													{resource.title}
												</a>
											)
										)}
									</Grid>
								</Grid>
								{/* <Grid item xs={6} md={2}>
									<Grid
										container
										direction='column'
										justifyContent='flex-start'
										alignItems='flex-start'>
										<Typography className={classes.headerText}>
											Products
										</Typography>
										{footer.productLinks.map((product, index) =>
											product.internalLink ? (
												<Link
													key={index}
													to={`/${product.slug.current}`}
													className={classes.linkText}>
													{product.title}
												</Link>
											) : (
												<a
													key={index}
													target='_blank' aria-label="Opens in a new tab"
													rel='noopener'
													href={product.slug.current}
													className={classes.linkText}>
													{product.title}
												</a>
											)
										)}
									</Grid>
								</Grid> */}

								<Grid item xs={6} md={3}>
									<Grid
										container
										direction='column'
										justifyContent='flex-start'
										alignItems='flex-start'
										style={{ maxWidth: '350px' }}>
										<Typography className={classes.headerText}>
											Reach Us
										</Typography>
										<a
											href={`tel:${footer.reachUsContent.salesNumber.replace('-','').replace('(','').replace(')','').replace(' ','')}`}
											style={{ textDecoration: 'none' }}>
											<Typography className={classes.linkText}>
												Sales: {footer.reachUsContent.salesNumber}
											</Typography>
										</a>
										<a
											href={`tel:${footer.reachUsContent.supportNumber.replace('-','').replace('(','').replace(')','').replace(' ','')}`}
											style={{ textDecoration: 'none' }}>
											<Typography className={classes.linkText}>
												Service: {footer.reachUsContent.supportNumber}
											</Typography>
										</a>
										<Typography
											className={classes.linkTextAddress}
											style={{ textAlign: 'left' }}>
											{footer.reachUsContent.address}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								container
								direction='row'
								justifyContent='space-evenly'
								alignItems='center'
								spacing={1}
								style={{ marginTop: '4rem' }}>
								<Grid item xs={12} lg={2} className={classes.footerLogo}>
									<Link to='/'>
										{footer.wwFooterLogo.asset.gatsbyImageData.images?.sources
											.length ? (
											<img
												src={footer.wwFooterLogo.asset.url}
												style={{
													height: '2rem',
													width: '',
													marginTop: '.5rem',
												}}
												alt='logo'
											/>
										) : (
											<GatsbyImage
												image={footer.wwFooterLogo.asset.gatsbyImageData}
												alt='logo'
											/>
										)}
									</Link>
								</Grid>
								<Grid
                  item
									container
                  justifyContent='space-evenly'
                  alignItems='center'
									xs={12}
									lg={8}>
                  <Grid
                    item
                    container
                    justifyContent='center'
                    alignItems='center'
                    xs={12}
                    lg={3}
                    className={classes.footerSocialContainer}>
                    <a
                      href='https://www.workwave.com/contact-us'
                      target='_blank' aria-label="Opens in a new tab"
                      className={classes.linkText}
                      style={{ marginBottom: 0 }}>
                      Copyright©  {new Date().getFullYear()}
                    </a>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent='center'
                    alignItems='center'
                    xs={12}
                    lg={2}
                    className={classes.footerSocialContainer}>
                    <a
                      href='https://www.workwave.com/privacy-policy'
                      target='_blank' aria-label="Opens in a new tab"
                      className={classes.linkText}
                      style={{ marginBottom: 0 }}>
                      Privacy Policy
                    </a>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent='center'
                    alignItems='center'
                    xs={12}
                    lg={2}
                    className={classes.footerSocialContainer}>
                    <a
                      href='https://www.workwave.com/terms-of-use'
                      target='_blank' aria-label="Opens in a new tab"
                      className={classes.linkText}
                      style={{ marginBottom: 0 }}>
                      Terms of Use
                    </a>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent='center'
                    alignItems='center'
                    xs={12}
                    lg={3}
                    className={classes.footerSocialContainer}>
                    <a
                      href='https://www.workwave.com/end-of-life-policy'
                      target='_blank' aria-label="Opens in a new tab"
                      className={classes.linkText}
                      style={{ marginBottom: 0 }}>
                      End of Life Policy
                    </a>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent='center'
                    alignItems='center'
                    xs={12}
                    lg={2}
                    className={classes.footerSocialContainer}>
										<Typography
                    className={classes.linkText}
                    style={{ marginBottom: 0 }}>
											SOC-1 Certified
										</Typography>
                  </Grid>
								</Grid>
								<Grid
									item
									xs={12}
									lg={2}
									className={classes.footerSocialContainer}
                  style={{ paddingLeft: med ? 0 : '20px' }}>
									{' '}
									<a
										className={classes.linkTextSocial}
										href='https://www.facebook.com/WorkWaveCo'
										target='_blank' aria-label="Opens in a new tab"
										rel='noopener noreferrer'
										style={{ textDecoration: 'none' }}>
										<FontAwesomeIcon
											title='facebook'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
											}}
											icon={['fab', 'square-facebook']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://twitter.com/WorkWave'
										target='_blank' aria-label="Opens in a new tab"
										rel='noopener noreferrer'
										style={{ textDecoration: 'none' }}>
										{' '}
										<FontAwesomeIcon
											title='twitter'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '3px' : '5px',
											}}
											icon={['fab', 'square-x-twitter']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://www.instagram.com/workwave/'
										target='_blank' aria-label="Opens in a new tab"
										rel='noopener noreferrer'
										style={{ textDecoration: 'none' }}>
										{' '}
										<FontAwesomeIcon
											title='instagram'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '2px' : '5px',
											}}
											icon={['fab', 'square-instagram']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://www.youtube.com/user/MarathonDataLLC'
										target='_blank' aria-label="Opens in a new tab"
										rel='noopener noreferrer'
										style={{ textDecoration: 'none' }}>
										<FontAwesomeIcon
											title='youtube'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '6px' : '9px',
											}}
											icon={['fab', 'square-youtube']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://www.linkedin.com/company/workwave/'
										target='_blank' aria-label="Opens in a new tab"
										rel='noopener noreferrer'
										style={{ textDecoration: 'none' }}>
										{' '}
										<FontAwesomeIcon
											title='linkedin'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '3px' : '5px',
											}}
											icon={['fab', 'linkedin']}
										/>
									</a>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
};
