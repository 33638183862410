import React, { useState, createContext } from 'react';

const FormContext = createContext();

export function FormProvider({ children }) {
	const [isLoaded, setIsLoaded] = useState(false);
	//marketo is a pain to work with. if you're having issues with duplicate forms, check that the parent component
	//is not rendering multiple times due to state changes in that component
	return (
		<FormContext.Provider
			value={{
				isLoaded,
				setIsLoaded,
			}}>
			{children}
		</FormContext.Provider>
	);
}

export default FormContext;
